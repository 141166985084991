import React from "react"
import PrizeDraw from "../../components/body/pages/incentive-program/prizeDraw"
import NoheaderFooter from "../../components/Noheaderfooter"
import SEO from "../../components/seo"

const CashPrize = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/incentive-program/prize-draw/"}
      title="Kuda Incentive Program"
    />
    <PrizeDraw />
  </NoheaderFooter>
)
export default CashPrize
